
import { defineComponent } from "vue";
import LogInForm from "@/components/forms/Login.vue";

export default defineComponent({
  name: "Admin",
  components: {
    LogInForm,
  },
  mounted() {
    localStorage.removeItem("user");
    localStorage.removeItem("walletName");
    localStorage.removeItem('phantom-token');
  },
});
