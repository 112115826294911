
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AlertBlock from "@/components/molecules/AlertBlock.vue";

export default defineComponent({
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertBlock,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("Login is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      messageAll: "",
      schema,
      dataNames: {
        logIn: 'Log in'
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {},
  methods: {
    handleLogin(user) {
      this.$store.dispatch("auth/login", user).then(
        (res) => {
          this.$router.push("/admin/catalog");
        },
        (error) => {
          this.loading = false;
          this.message = error.response.data.errors;
          this.messageAll = error.response.data;
        }
      );
    },
  },
});
